import { useEffect, useState } from 'react'; 

import { Link } from 'react-router-dom'
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";
import {getPrincipalMessage} from'../Service/Api'
const PrincipalMessage = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Principal Message - Tagore Public School';  
      }, []);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
       <Header />
            <Breadcrumb mid="About" breadcrumbName="Principal's Message" />
                <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='title'>
                        <h3><span>Principal's</span> Message</h3>
                        </div> 
                    </div>
                </div>
                
                {data?.length > 0 ? data?.filter((div) => div.category === "Principal").map((item, index) => ( 
                    <div className="clearfix" key={index}>
                     <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt="Tagore Public School Faridabad"/>
                
                       <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p> 
                      <p className="name"> <b><div dangerouslySetInnerHTML={{ __html: item.name }} /></b><span> <div dangerouslySetInnerHTML={{ __html: item.category }} /> </span></p>
              
                 
                </div> 
                )) : emptyData.map((item) => (
                    <div className="clearfix"> 
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/director.jpg" className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt="Tagore Public School Faridabad"/>
                    <p>
                            <b>Dear parents, students and fellow educators,</b></p>
                            <p>With utmost delight and learning gained from five decades of nurturing schools, I crave to pour-in the best  of my aspirations, inspiration and knowledge in pursuit of our  shared dream of Tagore Public School in Sector 89, Faridabad. <br></br>
</p><p>
                            Tagore believed that education, like a blossoming flower, should flourish as a symphony between tradition and progress. A harmonious synthesis of knowledge and innovation shall form the very fabric of Tagore Public School.<br></br>
                            In this grand Odyssey,  we dedicate ourselves to kindling the sparks of curiosity and inculcating  love for learning, ensuring every child blooms into a well-rounded individual.<br></br>
                            Our vision reflects a perfect tapestry, assimilating the transformative power of technology, enabling our pupils to thrive amid the rapidly-evolving digital landscape.<br></br>
       </p><p>
                            We envision education transcending traditional boundaries, traversing luminous corridors of possibility. <br></br>
</p><p>
                            We commit ourselves to sculpting erudite learners who embody global citizenship, cultural sensitivity, and unwavering dedication to personal evolution.
       </p><p>

                            In the resounding echoes of Tagore's words, "Do not limit a child's education to your own dreams,  they are born in an era far beyond ours."<br></br>
                            Anticipating your blessings, 
                            </p>
                            <p className="name"> <b> Deepika Tarun Sharma</b><br></br><span> Principal </span></p>
                    </div> 
                    ))}
             </div>
             
        </div> 
       <Footer/>
       <Copyright />
     </>
  )
}

export default PrincipalMessage
