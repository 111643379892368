import React, {useState, useEffect}from 'react';
import {Link} from 'react-router-dom'

import {getPrincipalMessage} from '../Service/Api'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const PrincipalMessage = () => {
    const [selectedTestimonial, setSelectedTestimonial] = useState(0);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
  
    useEffect(() => {
        const fetchData = async () => {
          const messages = await getPrincipalMessage();
          console.log("Messages fetched:", messages);  // Log to see the data
          const filterData = messages.filter((item) => item.category === "Principal");
          const filterData1 = messages.filter((item1) => item1.category === "Chairman");
          const filterData2 = messages.filter((item1) => item1.category === "Director");
          setData(filterData);
          setData1(filterData1);
          setData2(filterData2);
          console.log("Filtered Principal Data:", filterData);
          console.log("Filtered Chairman Data:", filterData1);
          console.log("Filtered Director Data:", filterData2);
        };
        fetchData();
      }, []);
     
    var settings = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoplay: true,
        dots: false,
        controls: true,
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1600: {
                items: 1,
            }
        }
    };

    return (

        <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
            {data.length > 0 ? data.map((item, index) =>(
                  <div className="item" key={index}>
                  <div className="message-block">
                      <div className='message-image animateMe' data-animation="fadeInRight">
                          <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`}  alt="Tagore Public School, Faridabad" />
  
                      </div>
  
  
                      <div className='message-content animateMe' data-animation="fadeInLeft">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/message.gif" className="quotes" alt="Tagore Public School, Faridabad" />
                          <div className='princi-Name'>
  
                              <h3>{item.name}</h3>
                              <p>{item.designation}</p>
                          </div>
                          <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p>
  
                      {/* <div class="button light">View More <div class="img"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /></div></div> */}
                      <Link to="/PrincipalMessage">
                      <div class="button light padding">&nbsp;
                      <span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png"  alt="Tagore Public School, Faridabad" /> 
                      </div>
                      </Link>
                  </div>
                  </div>
              </div>
            )):(
                <div className="item">
                <div className="message-block">
                    <div className='message-image animateMe' data-animation="fadeInRight">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/principal.jpg" alt="Tagore Public School, Faridabad" ></img>
                    </div>
                    <div className='message-content animateMe' data-animation="fadeInLeft">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/message.gif" className="quotes"  alt="Tagore Public School, Faridabad" ></img>
                        <div className='princi-Name'>

                            <h3>Deepika Tarun Sharma</h3>
                            <p>Principal</p>
                        </div>
                        <p>.
                            </p>
                        {/* <div class="button light">View More <div class="img"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /></div></div> */}
                      <Link to="/PrincipalMessage">  <div class="button light padding">&nbsp;<span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /> </div></Link>
                    </div>


                </div>
            </div>
                 
            )}
            {data1.length > 0 ? data1.map((item1, index) =>(
                  <div className="item" key={index}>
                  <div className="message-block">
                      <div className='message-image animateMe' data-animation="fadeInRight">
                          <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item1.attachments}`} />
  
                      </div>
  
  
                      <div className='message-content animateMe' data-animation="fadeInLeft">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/message.gif" className="quotes" />
                          <div className='princi-Name'>
  
                              <h3>{item1.name}</h3>
                              <p>{item1.designation}</p>
                          </div>
                          <p><div dangerouslySetInnerHTML={{ __html: item1.message }} /></p>
  
                      {/* <div class="button light">View More <div class="img"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /></div></div> */}
                      <Link to="/ChairmanMessage">
                      <div class="button light padding">&nbsp;
                      <span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" alt="Tagore Public School, Faridabad"  /> 
                      </div>
                      </Link>
                  </div>
                  </div>
              </div>
           )):(
            <div className="item">
                  <div className="message-block">
                      <div className='message-image animateMe' data-animation="fadeInRight">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/principal.jpg" alt="Tagore Public School, Faridabad"  />
  
                      </div>
  
  
                      <div className='message-content animateMe' data-animation="fadeInLeft">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/message.gif" className="quotes" alt="Tagore Public School, Faridabad"  />
                          <div className='princi-Name'>
  
                              <h3>Sudesh Gupta</h3>
                              <p>Chairman</p>
                          </div>
                          <p>I write this message not only as a Chairman but as a beholder of vision,  with a resolute determination to shape our common dream of of Tagore Public School, sector 89.<br></br>
                              I feel inspired to  weave a strong fabric of holistic education, and a vision for a future that resonates with the aspirations of a growing India. Let us embark on a transformative journey, where the classrooms become breeding grounds for innovation and excellence.<br></br>
                              The synergy between technology and education is profound. Blending our cultural heritage with  scientific advancements shall be the cornerstone of our mission.<br></br>
                              Education must be more than mere transmission of knowledge. We aspire to foster an environment where collaboration thrives,  creativity is boundless, and critical thinking shapes pupils' vision.
                          Let us march forward forging a destiny that shall make Tagore Public School the epitome of evolving brilliance.</p>
  
                      {/* <div class="button light">View More <div class="img"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /></div></div> */}
                      <Link to="/ChairmanMessage">
                      <div class="button light padding">&nbsp;
                      <span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png"  alt="Tagore Public School, Faridabad" /> 
                      </div>
                      </Link>
                  </div>
                  </div>
              </div>
            )}


{data2.length > 0 ? data2.map((item1, index) =>(
                  <div className="item" key={index}>
                  <div className="message-block">
                      <div className='message-image animateMe' data-animation="fadeInRight">
                          <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item1.attachments}`} />
  
                      </div>
  
  
                      <div className='message-content animateMe' data-animation="fadeInLeft">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/message.gif" className="quotes" />
                          <div className='princi-Name'>
  
                              <h3>{item1.name}</h3>
                              <p>{item1.designation}</p>
                          </div>
                          <p><div dangerouslySetInnerHTML={{ __html: item1.message }} /></p>
  
                      {/* <div class="button light">View More <div class="img"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /></div></div> */}
                      <Link to="/director">
                      <div class="button light padding">&nbsp;
                      <span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" alt="Tagore Public School, Faridabad"  /> 
                      </div>
                      </Link>
                  </div>
                  </div>
              </div>
           )):(
            <div className="item">
                  <div className="message-block">
                      <div className='message-image animateMe' data-animation="fadeInRight">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/director.jpg" alt="Tagore Public School, Faridabad"  />
  
                      </div>
  
  
                      <div className='message-content animateMe' data-animation="fadeInLeft">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/message.gif" className="quotes" alt="Tagore Public School, Faridabad"  />
                          <div className='princi-Name'>

                            <h3>Manorma Arora</h3>
                            <p>Academic Director</p>
                        </div>
                        <p>
                            Dear parents, students and fellow educators,<br></br>
                            With utmost delight and learning gained from five decades of nurturing schools, I crave to pour-in the best  of my aspirations, inspiration and knowledge in pursuit of our  shared dream of Tagore Public School in Sector 89, Faridabad. <br></br>

                            Tagore believed that education, like a blossoming flower, should flourish as a symphony between tradition and progress. A harmonious synthesis of knowledge and innovation shall form the very fabric of Tagore Public School.<br></br>
                            In this grand Odyssey,  we dedicate ourselves to kindling the sparks of curiosity and inculcating  love for learning, ensuring every child blooms into a well-rounded individual.<br></br>
                            Our vision reflects a perfect tapestry, assimilating the transformative power of technology, enabling our pupils to thrive amid the rapidly-evolving digital landscape.<br></br>
                            <br></br>
                            We envision education transcending traditional boundaries, traversing luminous corridors of possibility. <br></br>

                            We commit ourselves to sculpting erudite learners who embody global citizenship, cultural sensitivity, and unwavering dedication to personal evolution.
                            <br></br>

                            In the resounding echoes of Tagore's words, "Do not limit a child's education to your own dreams,  they are born in an era far beyond ours."<br></br>
                            Anticipating your blessings, 
                            </p>
  
                      {/* <div class="button light">View More <div class="img"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /></div></div> */}
                      <Link to="/director">
                      <div class="button light padding">&nbsp;
                      <span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png"  alt="Tagore Public School, Faridabad" /> 
                      </div>
                      </Link>
                  </div>
                  </div>
              </div>
            )}
        </OwlCarousel >

    );
};

export default PrincipalMessage;