import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getGallery } from '../Service/Api';
import { Link } from  'react-router-dom'
const Gallery = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        const topperData = await getGallery();
        setData(topperData);
      };
      fetchData();
    }, []);
  
    useEffect(() => {
      console.log("events_data", data[0]);
    }, [data]);
  
    const emptyArray = [
      { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/about.jpg", description: "Stay tuned for latest updates" },
      { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/about.jpg", description: "Stay tuned for latest updates" },
      { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/about.jpg", description: "Stay tuned for latest updates" },
    ];
  
    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: false,
        items: 5,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 2,
            },
            768: {
                items: 4,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
            1600: {
                items: 5,
            }
        }
    };
    return (
        <>
            <div className="gallery-box"> 
                <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
                {data?.length > 0 ? data?.filter((div) => div.category === "PhotoGallery").map((item, index) => (
                    <div className="item">
                        <Link to={`/SubGallery?id=${item._id}`}>
                        <div className="gal-img">
                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}  alt="Tagore Public School, Faridabad" />
                            <div class="description">                              
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/zoom.png"  alt="Tagore Public School, Faridabad" />
                                <h3>{data.title}</h3> 
                            </div>
                        </div>
                        </Link>
                    </div>
                    )) : (
                    <div className="item">
                        <div className="gal-img">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/about.jpg"  alt="Tagore Public School, Faridabad" />
                            <div class="description">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/zoom.png"  alt="Tagore Public School, Faridabad" />
                                <h3>Gallery Title</h3> 
                            </div>
                        </div>
                    </div>
                    )}
                    {/* <div className="item">
                        <div className="gal-img">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/gallery3.png" />
                            <div class="description">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/zoom.png" />
                                <h3>Gallery Title</h3> 
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="gal-img">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/gallery4.png" />
                            <div class="description">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/zoom.png" />
                                <h3>Gallery Title</h3> 
                            </div>
                        </div>
                    </div> */}
                    
                </OwlCarousel>
            </div>
        </>
    )
}
export default Gallery