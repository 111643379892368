import React, { useEffect, useState } from 'react';
import { getEvents } from '../Service/Api';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Events = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
      const getData = async () => {
        const datas = await getEvents();
        setData(datas);
      };
      getData();
    }, []);
    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: true,
        items: 4,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 3,
            }, 
            1400: {
                items: 3,
            },
            1600: {
                items: 4,
            }
        }
    };
 


    return (
        <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
            {data?.length > 0 &&
        data?.map((item, index) => ( 
            <div className="item" key={index}>
                <div className='evt-blk'>
                    <div className="evt-img">
                        <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.images}`}  alt="Tagore Public School, Faridabad"  />
                        <p className='date'>
    {new Date(item.date).toLocaleString('en-US', { month: 'short' })} <span>{new Date(item.date).getDate()}</span> {new Date(item.date).getFullYear()}
</p>

                        <div className='readmore'>READ MORE  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png"  alt="Tagore Public School, Faridabad" /></div>
                        <div className="evt-title"><h3>{item.title}</h3></div> 
                        <p>{item.description}</p>
                    </div>
                    <div className='evt-content'>                        
                        <p>{item.venue}</p>
                    </div>
                </div>
            </div>
            // <div className="item">
            //     <div className='evt-blk'>
            //         <div className="evt-img">
            //             <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/event2.jpg" />
            //             <p className='date'>
            //                 Sep <span>05</span> 2024
            //             </p>
            //             <div className='readmore'>READ MORE  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /></div>
            //             <div className="evt-title"><h3>Onam Celebration</h3></div> 
            //             <p>Onam is an annual harvest and cultural festival related to Hinduism that is celebrated mostly by the people of Kerala.</p>
            //         </div>
            //         <div className='evt-content'>                        
            //             <p>School Campus </p>
            //         </div>
            //     </div>
            // </div>
            // <div className="item">
            //     <div className='evt-blk'>
            //         <div className="evt-img">
            //             <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/event3.jpg" />
            //             <p className='date'>
            //                 Aug <span>11</span> 2024
            //             </p>
            //             <div className='readmore'>READ MORE  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/arrow.png" /></div>
            //             <div className="evt-title"><h3>Tulsidas Jayanti</h3></div> 
            //             <p>Tulsidas Jayanti is observed every year in the remembrance of Goswami Tulsidas.</p>
            //         </div>
            //         <div className='evt-content'>                        
            //             <p>School Campus </p>
            //         </div>
            //     </div>
            // </div>
        
            ))} 
        </OwlCarousel>
    );
};

export default Events;
