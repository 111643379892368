import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Breadcrumb from '../components/Breadcrumb'
const ContactUs = () => {
    return (
        <>
            <Header />
            {/* <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Contact Us </li>
            </ul>
       </div> */}
            <Breadcrumb mid="Contact" breadcrumbName="Contact Us" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <div>
                                    <h3><span>Contact </span> Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>                        
                        <div className='col-lg-6'>
                            <div className="contact-container">
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/location1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Address</h6>
                                        <p><Link to="https://www.google.com/maps/search/Block+G,+Sector+89,+BPTP+Park+Land+Near+Jain+Mandir,+Faridabad/@28.4089588,77.354807,15z/data=!3m1!4b1?hl=en&entry=ttu" target="_blank">
                                            Tagore Public School <br />
                                            Block G, Sector 89, Faridabad</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/phone1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Phones</h6>
                                        <p><Link to="tel:8527733139">  +91-8527733139</Link>  </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/mail1.png" className="img-fluid" alt="Tagore Public School" />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Email</h6>
                                        <p><Link to="mailto:tagoreschoolfbd89@gmail.com"> tagoreschoolfbd89@gmail.com</Link> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Block%20G,%20Sector%2089,%20BPTP%20Park%20Land%20Near%20Jain%20Mandir,%20Faridabad+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="350" style={{ border: " 0;" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>




                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs
