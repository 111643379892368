import React from "react";
import { Link } from 'react-router-dom';
import ContactBranch from '../components/ContactBranch'

const Footer = () => {
    return(
        <>
        <footer>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREFBD/public/Images/ftr-logo.png" alt="Tagore Public School, Faridabad" />
                        </div>
                        <div className="col-xl-3 col-lg-9">
                            <div className="quick-link">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><Link to='/'>Home</Link></li>
                                    <li><Link to='/about'>About Us</Link></li>
                                    <li><a href="/smc">School Management</a></li>
                                    <li><a href="/pageData">Admissions</a></li>
                                    <li><a href="/PrincipalMessage">Principal’s Desk</a></li>
                                    <li><a href="/pageData">Staff List</a></li>
                                    <li><a href="/Gallery">Picture Gallery</a></li>
                                    <li><a href="/VideoGallery">Video Gallery</a></li>
                                    <li><a href="/pageData">Careers</a></li>
                                    <li><a href="/pageData">Student Login</a></li>
                                    <li><a href="/pageData">Parent Login</a></li>
                                    <li><a href="/pageData">Alumni Login</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2  col-lg-6">
                            <div className="cont-dtl">
                                <h3>Location</h3>
                                <p><i class="bi bi-geo-alt"></i> <span>Block G, Sector 89, Faridabad</span></p>
                                <p><i class="bi bi-telephone-fill"></i> <span><a href="tel: +91-8527733139"> +91-8527733139</a> </span></p>
                                <p><i class="bi bi-globe"></i><span> <a href="www.tps89.in" target="_blank">www.tps89.in</a></span></p>
                                 
                            </div>
                        </div>
                        
                        <div className="col-xl-2 col-lg-6">
                        <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Block%20G,%20Sector%2089,%20BPTP%20Park%20Land%20Near%20Jain%20Mandir,%20Faridabad+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="branch">
                                <ContactBranch />
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            
            </footer>
        </>
    )
}
export default Footer;