import React, { useEffect, useState } from 'react';
import { getNotification } from '../Service/Api';
import { Link } from 'react-router-dom';

const Popup = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const modalData = await getNotification();
        setData(modalData);
        if (modalData.length > 0) {
          setShowModal(true);
        }
      } catch {
        console.log('error');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (showModal) {
      // Initialize and show Bootstrap modal here
      const modalElement = document.getElementById('exampleModalToggle');
      const modal = new window.bootstrap.Modal(modalElement);
      modal.show();
    }
  }, [showModal]);

  return (
    <>
      {data?.length > 0 && (
        <div
          className="popup modal fade"
          id="exampleModalToggle"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div id="modalH" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  {data.map((item, index) => (
                    <div
                      key={index}
                      className={`carousel-item${index === 0 ? ' active' : ''}`}
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div className="modal-body">
                        <div className="card modalcard">
                          <div className="row">
                            <div className="card-body">
                              <h1>{item.title}</h1>
                              <img
                                src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image[0]}`}
                                className="img-fluid"
                                alt="Tagore Public School, Faridabad" 
                              />
                              <p>{item.desc}</p>
                              <div className="clearfix"></div>
                              <div className="modal-bottom">
                              {item.attachments.length > 0 ? (
                                <Link
                                  target="_blank"
                                  to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}
                                  className="btn btn-primary btn-sm"
                                >
                                  Attachment
                                </Link>
                                  ): (<></>)}
                                {item.url ? (
                                    <Link
                                    target="_blank"
                                    to={`${item.url}`}
                                    className="btn btn-primary btn-sm"
                                  >
                                    Click Here
                                  </Link>
                                ): (<></>)}
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#modalH"
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#modalH"
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
